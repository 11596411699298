<template>
  <menu
    class="cta-options"
    @mouseenter="() => (navigateIndex = -1)"
    v-class-mod:cta-options="`modal-size-${modalSize}`"
    v-focus-navigate="{ exitOnEsc: true }"
    v-lock-focus="true"
  >
    <template v-for="(option, key) in options" :key="key">
      <span v-if="option === 'seperator'" class="cta-options__seperator" />
      <cta-button
        v-else-if="'action' in option"
        :removeBorder="true"
        :size="buttonSize"
        :data-index="key"
        v-bind="option"
        :styling-options="{
          bgHover: 'menu-hover',
          bg: 'transparent',
          ...option.stylingOptions,
        }"
        class="cta-options__button"
        ref="optionElems"
      />
      <template v-else-if="'options' in option">
        <cta-button
          :removeBorder="true"
          v-bind="option.header"
          :size="buttonSize"
          :action="() => toggleGroup(key)"
          :icon-after="expandedGroups.includes(key) ? 'expand_less' : 'expand_more'"
          :styling-options="{
            bgHover: 'menu-hover',
            bg: expandedGroups.includes(key) ? 'menu-hover' : 'transparent',
          }"
          class="cta-options__button"
        />
        <div v-if="expandedGroups.includes(key)" class="cta-options__group">
          <cta-button
            v-for="(o, k) in option.options"
            :removeBorder="true"
            :size="buttonSize"
            :data-index="key"
            v-bind="o"
            :styling-options="{
              bgHover: 'menu-hover',
              bg: 'transparent',
              ...o.stylingOptions,
            }"
            class="cta-options__button"
            :key="k"
          />
        </div>
      </template>
      <span
        v-else
        class="cta-options__heading"
        v-class-mod:cta-options__heading="{ alignLeft: option.alignLeft, alignRight: option.alignRight }"
      >
        {{ option.title }}
      </span>
    </template>
  </menu>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { vFocusNavigate } from "@horizon56/directives/focus-navigate";
import { vLockFocus } from "@horizon56/directives/lock-focus";
import { ButtonSize, InputModalSize } from "@horizon56/styles/types";
import { isHtmlElem } from "@horizon56/utils";

import CtaButton, { CtaButtonProps } from "@/components/buttons/cta-button.vue";

export type Btn = Pick<
  CtaButtonProps,
  "action" | "title" | "feedbackIcon" | "iconBefore" | "disabled" | "explanation" | "stylingOptions"
>;

export type Group = {
  header: Pick<CtaButtonProps, "title" | "feedbackIcon" | "iconBefore" | "stylingOptions">;
  options: Btn[];
};

export type Heading = {
  title: string;
  alignLeft?: boolean;
  alignRight?: boolean;
};

export type Seperator = "seperator";

export type Option = Btn | Heading | Seperator | Group;

const props = withDefaults(defineProps<{ options: Option[]; buttonSize?: ButtonSize; modalSize?: InputModalSize }>(), {
  buttonSize: "large",
  modalSize: "large",
});

const navigateIndex = ref(-1);
const optionElems = ref();
const expandedGroups = ref<number[]>([]);

const ctaOptions = computed(() => props.options.filter((o): o is Btn => o !== "seperator" && "action" in o));

const toggleGroup = (key: number) => {
  if (expandedGroups.value.includes(key)) {
    expandedGroups.value = expandedGroups.value.filter((e) => e !== key);
  } else {
    expandedGroups.value.push(key);
  }
};

watch(
  () => navigateIndex.value,
  (idx) => {
    if (idx >= 0 && idx < ctaOptions.value.length) {
      const elem = optionElems.value?.find(
        (e: InstanceType<typeof CtaButton>) => e.$el?.dataset.index === idx.toString(),
      )?.$el;
      if (isHtmlElem(elem)) {
        elem.scrollIntoView({
          block: "nearest",
        });
      }
    }
  },
);
</script>

<style lang="scss" scoped>
.cta-options {
  background: var(--menu-bg);
  border-radius: var(--app-radius-medium);
  border: 1px solid var(--black-20);
  overflow: auto;
  padding: 0;
  @include set-scrollbar();
  @each $size in $inputModalSizes {
    &--modal-size-#{$size} {
      width: var(--app-input-modal-size-#{$size});
    }
  }
  &__button {
    width: 100%;
    text-align: start;
  }
  &__seperator {
    display: block;
    border-bottom: 1px solid var(--black-10);
  }
  &__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black-50);
    padding: 0 var(--app-spacing-size-medium);
    min-height: var(--app-button-height-medium);
    color: var(--black-50);
    &--alignLeft {
      justify-content: left;
    }
    &--alignRight {
      justify-content: right;
    }
    &:not(:first-child) {
      border-top: 1px solid var(--black-10);
    }
  }
  &__heading,
  &__group {
    background: var(--menu-hover);
  }
}
</style>
